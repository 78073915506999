<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M248 63.6923L248 192.923C248 208.217 235.602 220.615 220.308 220.615L35.6923 220.615C20.3983 220.615 8 208.217 8 192.923L8 63.6923M248 63.6923C248 48.3983 235.602 36 220.308 36L35.6923 36C20.3983 36 8 48.3983 8 63.6923M248 63.6923V66.6795C248 76.296 243.011 85.2239 234.821 90.2639L142.513 147.069C133.613 152.546 122.387 152.546 113.487 147.069L21.1788 90.2639C12.9889 85.2239 8 76.296 8 66.6795V63.6923"
      stroke="currentColor"
      stroke-width="15"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
